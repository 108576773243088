<template>
  <div>
    <div class="template">
      <div class="template_center">
        <span>模板中心 ></span>
        <div class="template_center_results">
          <div class="search_results">
            <span v-if="keyword != undefined">"{{ keyword }}" ></span>
            <span
              v-if="tfname != undefined"
              class="prodid_span"
              @click="deletetfname"
              >{{ tfname }} <i class="iconguanbi iconfont"></i
            ></span>
            <span
              v-if="prodid != undefined"
              class="prodid_span"
              @click="deleteprodid"
              >{{
                prodid == 1
                  ? "海报"
                  : prodid == 2
                  ? "H5"
                  : prodid == 3
                  ? "表单"
                  : ""
              }}
              <i class="iconguanbi iconfont"></i
            ></span>
            <p>
              共<span class="results">{{ resultsLength }}</span
              >个结果
            </p>
          </div>
        </div>
      </div>
      <div class="template_type">
        <span>类型:</span>
        <div class="template_type_results">
          <span
            v-for="(item, index) in labelList"
            :key="index"
            @click="checklabel(index, item.psid, item.prodid)"
            :class="labelid === index ? 'action' : ''"
            >{{ item.psname }}</span
          >
        </div>
      </div>
      <div class="template_type">
      <span>价格:</span>
      <div class="template_type_results">
        <span v-for="(item,index) in priceList" :key="index" :class="pricetTypeid === index?'action':''" @click="handleSearchPrice(index)">{{item}}</span>
      </div>
      </div>
    </div>
    <div class="itemContain">
      <div class="itemWrap" v-for="(item, index) in 6" :key="index">
        <product-item
          class="item"
          v-for="(product, index) in tplsList[index]"
          :key="index"
          :name="product.tname"
          :price="product.unitprice"
          :usenumber="product.nowcosttimes"
          :labelname="product.tlname"
          :tavatar="product.tavatar"
          :ossurl="ossurl"
          :tcode="product.tcode"
          :tfids="product.tfids.split(',')"
          :prodid="product.prodid"
          :psid="product.psid"
        />
      </div>
    </div>
    <div class="pagination">
      <a-pagination
        show-quick-jumper
        :default-current="1"
        :total="resultsLength"
        @change="junpPage"
        :page-size="48"
      />
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import { getShowtpl } from "@/api/manage";
import { showtplspec } from "@/api/home";
export default {
  data() {
    return {
      tplsList: [],
      labelList: ["全部", "海报", "H5", "表单"],
      labelid: 0,
      resultsLength: 0,
      totalPage: 3,
      pricetTypeid:0,
      priceList:["全部", "付费", "免费"],
      isfree:''
    };
  },
  mounted() {
    this.getshowtpl(this.prodid, this.keyword, this.psid, this.tfid,1,this.isfree);
    this.showtplspecs(null);
  },
  methods: {
    addRouteParameter(type,parameter) {
      //从路由最后新增参数
      let currentRoute = JSON.parse(
        JSON.stringify(this.$router.currentRoute.query)
      ); // 这里我们需要的应该是值，因此必须转为深拷贝
      if(type === 'psid'){
     Object.assign(currentRoute, { psid: parameter });
      }else if(type === 'prodid'){
          Object.assign(currentRoute, { prodid: parameter });
      }
      this.$router.push({ path: "/store/search", query: currentRoute });
    },
    deleteRouteParameter(type) {
      //从路由删除参数\
      let currentRoute = JSON.parse(
        JSON.stringify(this.$router.currentRoute.query)
      ); // 这里我们需要的应该是值，因此必须转为深拷贝
      if (type === "tfid") {
        delete currentRoute.tfid;
        delete currentRoute.tfname;
      }else if(type === 'psid'){
        delete currentRoute.psid;
      }
      else {
        delete currentRoute.prodid;
        delete currentRoute.psid;
      }
      this.$router.push({ path: "/store/search", query: currentRoute });
    },
    handleSearchPrice(index){
      this.pricetTypeid = index
      if(index === 0){
        this.isfree = ''
   this.getshowtpl(this.prodid,this.keyword,this.psid,this.tfid,1,'')
      }else{
        this.isfree = index
        this.getshowtpl(this.prodid,this.keyword,this.psid,this.tfid,1,this.isfree)
      }

    },
    junpPage(pageNumber) {
      this.getshowtpl(
        this.prodid,
        this.keyword,
        this.psid,
        this.tfid,
        pageNumber,
        this.isfree
      )
    },
    deletetfname() {
      this.deleteRouteParameter("tfid");
      this.getshowtpl(this.prodid, this.keyword, this.psid, "",1,this.isfree);
      this.showtplspecs(null);
      this.labelid = 0;
    },
    deleteprodid() {
      this.deleteRouteParameter();
      this.getshowtpl("", this.keyword, "", this.tfid,1,this.isfree);
      this.showtplspecs(null);
      this.labelid = 0;
    },
    showtplspecs(id) {
      if (id != null) {
        this.gettplspec(id);
      } else {
        this.labelList = [
          { prodid: 0, psid: null, psname: "全部" },
          { prodid: 1, psid: null, psname: "海报" },
          { prodid: 2, psid: null, psname: "H5" },
          { prodid: 3, psid: null, psname: "表单" },
        ];
      }
    },
    gettplspec(prodid) {
      let parame = {
        prodid: prodid,
      };
      showtplspec(parame).then((res) => {
        this.labelList = res.data;
        this.labelList.unshift({ psid: 0, psname: "全部" });
        for (var i = 0; i < this.labelList.length; i++) {
          if (this.psid == this.labelList[i].psid) {
            this.labelid = i;
            console.log(this.labelid);
          }
        }
      });
    },
    checklabel(id, psid, prodid) {
      this.labelid = id;
      if (psid != null) {
        if (psid === 0) {
          this.deleteRouteParameter('psid');
          this.getshowtpl(this.prodid, this.keyword, "", this.tfid,1,this.isfree);
        } else {
           this.addRouteParameter('psid',psid)
           this.getshowtpl(this.prodid, this.keyword, psid, this.tfid,1,this.isfree);
        }
      } else {
        if (prodid === 0) {
          this.getshowtpl("", this.keyword, "", this.tfid,1,this.isfree);
        } else {
          this.labelid = 0;
          this.gettplspec(prodid);
         this.addRouteParameter('prodid',prodid)
          this.getshowtpl(prodid, this.keyword, "", this.tfid,1,this.isfree);
        }
      }
    },
    getshowtpl(prodid, keyword, psid, tfid, pageNo,isfree) {
      let parameter = {
        pageSize: 48,
        prodid: prodid,
        keywords: keyword,
        psid: psid,
        tips: tfid,
        pageNo: pageNo,
        isfree:isfree
      };
      getShowtpl(parameter).then((res) => {
        if (res.code === 200) {
          var list = [[], [], [], [], [], []];
          this.resultsLength = res.data.total
          this.totalPage = res.data.last_page
          for (var i = 0; i < 8; i++) {
            for (var j = 0; j < 6; j++) {
              if (res.data.data[i * 6 + j] != undefined) {
                list[j].push(res.data.data[i * 6 + j]);
              }
            }
          }
          this.tplsList = list;
          console.log(this.tplsList);
        }
      });
    },
  },
  components: {
    ProductItem,
  },
  props: ["prodid", "ossurl", "keyword", "tfid", "tfname", "psid"],
  watch: {
    prodid: {
      handler(val) {
        if (val != undefined) {
          this.showtplspecs(val);
          this.getshowtpl(val, this.keyword, "", this.tfid,1,this.isfree);
        }
      },
      immediate: true,
    },
    keyword: {
      handler(val) {
        if (val != undefined) {
          this.showtplspecs(null);
          this.getshowtpl(this.prodid, val, "", this.tfid,1,this.isfree);
        }
      },
      immediate: true,
    },
    tfid: {
      handler(val) {
        if (val != undefined) {
          this.getshowtpl(this.prodid, this.keyword, "", val,1,this.isfree);
        }
      },
      immediate: true,
    },
    psid: {
      handler: function (val) {
        if (val != undefined) {
          this.getshowtpl(this.prodid, this.keyword, val, this.tfid,1,this.isfree);
          scrollTo(0,0)
        }
      },
      immediate: true,
    },
  },
};
</script>


<style lang="less" scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.template {
  padding: 0 5px;
}
.template_center {
  color: #a5aec5;
  margin-bottom: 25px;
  .template_center_results {
    margin-left: 15px;
    display: inline-block;
    color: #333;
    .search_results {
      .prodid_span {
        cursor: pointer;
        display: inline-block;
        background-color: #f5f7fb;
        padding: 0 6px 0 8px;
        border-radius: 4px;
        color: #666;
        font-size: 14px;
        line-height: 24px;
        vertical-align: middle;
        i {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
          margin-top: -2px;
          margin-left: 2px;
        }
      }
      .prodid_span:hover {
        color: #0052ff;
      }
      p {
        display: inline-block;
        margin-bottom: 0;
        color: #a5aec5;
      }
      span {
        margin-right: 10px;
      }
      .results {
        margin-right: 0px;
        color: #0052ff;
      }
    }
  }
}
.template_type {
  color: #a5aec5;
  margin-bottom: 20px;
  .template_type_results {
    display: inline-block;
    margin-left: 40px;
    span {
      margin-right: 5px;
      color: #333;
      cursor: pointer;
      display: inline-block;
      min-width: 52px;
      text-align: center;
      height: 23px;
      line-height: 23px;
      padding: 0 10px;
    }
    .action {
      background-color: #f0f6ff;
      color: #0052ff;
      border-radius: 19px;
    }
  }
}
.itemContain {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: hidden;
}
.itemWrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  flex-basis: 16%;
  // display: flex;
  // flex-wrap: wrap;
  .item {
    margin: 0 7px 10px 0;
    // break-inside: avoid;
  }
}
</style>