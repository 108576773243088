<template>
  <div>
    <!-- 头部搜素内容 -->
    <!-- <SearchHeader /> -->
    <!-- 实例部分 -->
    <SearchItem
      :prodid="prodid"
      :ossurl="ossurl"
      :keyword="keyword"
      :tfid="tfid"
      :tfname="tfname"
      :psid="psid"
    ></SearchItem>
  </div>
</template>


<script>
import SearchItem from "@/components/SearchItem";
// import SearchHeader from "@/components/SearchItem/searchHeader.vue";
import { getossurl } from "@/api/home";
export default {
  name: "Search",
  components: {
    SearchItem,
    // SearchHeader
  },
  data() {
    return {
      ossurl: "",
      prodid: "",
      keyword: "",
      tfid: "",
      tfname: "",
      psid:''
    };
  },
  created() {
    getossurl().then((res) => {
      this.ossurl = res.data;
    });
  },
  watch: {
    $route: {
      handler: function (val) {
        this.prodid = val.query.prodid
        this.tfid = val.query.tfid
        this.tfname = val.query.tfname
        this.keyword = val.query.keywords
        this.psid = val.query.psid
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
};
</script>